import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { getRatingMessage } from "@/feature-survey/constants.mjs";
import { StarButton, StarGroup } from "@/feature-survey/Survey.style.jsx";
import StarSvg from "@/inline-assets/survey-star.svg";

const Star = (props) => {
  return (
    <StarButton
      role="button"
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      onClick={props.onClick}
    >
      <StarSvg className="survey-star" data-selected={props.marked && "true"} />
    </StarButton>
  );
};

const StarRating = (props) => {
  const { selectedRating, setRatingMessage, setRating } = props;

  const { t } = useTranslation();
  const [currentHoveredStar, setCurrentHoveredStar] = React.useState(0);

  const handleHover = useCallback(
    (hoveredRating = 0) =>
      () => {
        setCurrentHoveredStar(hoveredRating || selectedRating);
        setRatingMessage(
          t(...getRatingMessage(hoveredRating || selectedRating)),
        );
      },
    [selectedRating, setRatingMessage, t],
  );

  const handleRatingClick = useCallback(
    (hoveredRating: number) => () => {
      setRating(hoveredRating);
    },
    [setRating],
  );

  return (
    <StarGroup>
      {Array.from({ length: 5 }, (_, i) => (
        <Star
          key={i}
          hoveredRating={i + 1}
          marked={currentHoveredStar && currentHoveredStar > i}
          onMouseOver={handleHover(i + 1)}
          onMouseOut={handleHover()}
          onClick={handleRatingClick(i + 1)}
        />
      ))}
    </StarGroup>
  );
};

export default StarRating;
