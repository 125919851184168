import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.jsx";
import { TextArea } from "clutch/src/TextArea/TextArea.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { postData } from "@/__main__/get-data.mjs";
import mainRefs from "@/__main__/refs.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import {
  appURLs,
  GAME_BACKEND_NAMES,
  GAME_SHORT_NAMES,
} from "@/app/constants.mjs";
import noopModel from "@/data-models/no-op.mjs";
import {
  overrideTimesUntilFirstSurvey,
  reduceTimesUntilFirstSurveyByOne,
  setNextSurveyScheduleAfterDismiss,
  setNextSurveyScheduleAfterSubmit,
} from "@/feature-survey/actions.mjs";
import type { Survey as SurveyType } from "@/feature-survey/constants.mjs";
import {
  DEFAULT_SURVEY_MESSAGES,
  EVENT_USER_SURVEY,
  getRatingMessage,
} from "@/feature-survey/constants.mjs";
import StarRating from "@/feature-survey/StarRating.jsx";
import {
  CheckIcon,
  CloseButton,
  SurveyContainer,
  SurveyMessageContainer,
  SurveyRatingsContainer,
  SurveyText,
} from "@/feature-survey/Survey.style.jsx";
import BlitzIcon from "@/inline-assets/blitz.svg";
import BlitzCloseIcon from "@/inline-assets/close-icon.svg";
import { devDebug } from "@/util/dev.mjs";
import { useGameSymbol } from "@/util/game-route.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Survey({
  surveyData,
  surveyIsOpen,
}: {
  surveyData: SurveyType;
  surveyIsOpen: React.MutableRefObject<boolean>;
}) {
  const route = useRoute();
  const gameSymbol = useGameSymbol();
  const { t } = useTranslation();
  const lastRoutePath = useRef<string>();
  const surveyAlreadySentRef = useRef<boolean>(false);
  const lastRatingAlreadySentRef = useRef<number | null>(null);
  const { survey, settings, volatile } = useSnapshot(readState);
  const [ratingMessage, setRatingMessage] = useState(
    t(...getRatingMessage(0)) as string,
  );
  const [isDismissed, setIsDismissed] = useState(false);
  const [rating, setRating] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [surveyMessage, setSurveyMessage] = useState("");

  const { currentPath, parameters, path } = route || {};
  const gameBackendName = GAME_BACKEND_NAMES[gameSymbol];
  const gameShortName = GAME_SHORT_NAMES[gameSymbol];
  const { nextSurveySchedules, gamesUntilSurveyStart } = survey || {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isSurveyDisabled } = (settings["survey"] || {}) as any; // this probably shouldn't be stored in `settings`?

  const submitMessage = useCallback(
    (e) => {
      e.preventDefault();

      const surveyObject = {
        path: currentPath,
        game: gameBackendName,
        rating,
        survey_key: surveyData.key,
        survey_message: surveyMessage,
        route: typeof path === "string" ? path : path?.originalPath,
        route_params: parameters,
        sub_features: Object.values(volatile?.subFeatures || {}),
        version: mainRefs.PKG_VERSION,
      };

      postData(
        {
          url: `${appURLs.UTILS}/survey`,
          body: Object.assign(surveyObject, {
            survey_rating: rating,
          }),
        },
        noopModel,
        undefined,
      );
      setSubmitted(true);

      eventBus.emit(EVENT_USER_SURVEY, surveyObject);
      surveyAlreadySentRef.current = true;

      setTimeout(() => {
        setIsDismissed(true);
        surveyIsOpen.current = false;
      }, 6 * 1000); // This is delayed until after the fade animation
    },
    [
      currentPath,
      gameBackendName,
      rating,
      surveyMessage,
      path,
      parameters,
      volatile?.subFeatures,
      surveyIsOpen,
    ],
  );

  useEffect(() => {
    if (lastRoutePath.current === currentPath) {
      return;
    }
    if (gamesUntilSurveyStart === null) {
      overrideTimesUntilFirstSurvey(4);
    } else if (gamesUntilSurveyStart > 0) {
      reduceTimesUntilFirstSurveyByOne();
    }
    lastRoutePath.current = currentPath;
  }, [currentPath, gamesUntilSurveyStart]);

  useEffect(() => {
    if (!rating || !survey?.nextSurveySchedules) return;
    if (rating === lastRatingAlreadySentRef.current) return;
    setNextSurveyScheduleAfterSubmit(gameSymbol);
    lastRatingAlreadySentRef.current = rating;
  }, [rating, gameSymbol, survey?.nextSurveySchedules]);

  const handlePreSubmitCloseButton = useCallback(async () => {
    setIsDismissed(true);
    if (!lastRatingAlreadySentRef.current) {
      setNextSurveyScheduleAfterDismiss(gameSymbol);
    } else {
      await submitMessage({ preventDefault: () => {} });
    }
    surveyIsOpen.current = false;
  }, [gameSymbol, submitMessage, surveyIsOpen]);

  let skip = false;

  switch (true) {
    case isSurveyDisabled:
      devDebug("Survey is disabled, skipping survey");
      skip = true;
      break;
    case gamesUntilSurveyStart > 0:
      devDebug("Not enough games played, skipping survey");
      skip = true;
      break;
    case isDismissed:
      devDebug("Survey is dismissed, skipping survey");
      skip = true;
      break;
    case !nextSurveySchedules ||
      nextSurveySchedules[gameShortName] === undefined:
      devDebug("No survey schedule, skipping survey");
      skip = true;
      break;
    case !rating && nextSurveySchedules[gameShortName] > new Date().getTime():
      devDebug("Not enough time has passed, skipping survey");
      skip = true;
      break;
    case !gameShortName:
      devDebug("No game short name, skipping survey");
      skip = true;
      break;
    case !gameBackendName:
      devDebug("No game backend name, skipping survey");
      skip = true;
      break;
    default:
  }

  if (skip) {
    surveyIsOpen.current = false;
    return null;
  }

  surveyIsOpen.current = true;

  if (submitted) {
    return (
      <SurveyContainer className="result">
        <CheckIcon />
        <SurveyText className="type-body2-form">
          {t(...DEFAULT_SURVEY_MESSAGES.POST_SURVEY_MESSAGE)}
        </SurveyText>
      </SurveyContainer>
    );
  }

  return (
    <SurveyContainer onSubmit={submitMessage}>
      <SurveyText className="type-body2-form">
        <BlitzIcon />
        {t(...DEFAULT_SURVEY_MESSAGES.SURVEY_QUESTION)}
      </SurveyText>
      <SurveyRatingsContainer>
        <StarRating
          selectedRating={rating}
          setRatingMessage={setRatingMessage}
          setRating={setRating}
        />
        <SurveyText $sent={Boolean(rating)} className="type-body2-form result">
          {ratingMessage}
        </SurveyText>
      </SurveyRatingsContainer>
      {Boolean(rating) && (
        <SurveyMessageContainer $show={Boolean(rating)}>
          <TextArea
            className="survey-text-field"
            autoSize
            placeholder={t(
              "survey:feedbackInput:placeholder",
              "Share your thoughts...",
            )}
            onInput={(e) => setSurveyMessage(e.currentTarget.value)}
          />
          <Button type="submit" emphasis="high">
            {t("survey:submitButton", "Submit")}
          </Button>
        </SurveyMessageContainer>
      )}
      <CloseButton
        type="submit"
        role="button"
        onClick={handlePreSubmitCloseButton}
      >
        <BlitzCloseIcon />
      </CloseButton>
    </SurveyContainer>
  );
}

export default Survey;
