import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  readState,
} from "@/__main__/app-state.mjs";
import { isPersistent, isVolatile, MAX_TIME } from "@/__main__/constants.mjs";
import { readData } from "@/__main__/get-data.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import type { GameHash } from "@/app/initial-state-defaults.mjs";
import { makeHashByGames } from "@/app/initial-state-defaults.mjs";
import { DAY, WEEK } from "@/feature-survey/constants.mjs";
import addInitialPath from "@/util/add-initial-path.mjs";
import clone from "@/util/clone.mjs";
import { devDebug, devWarn } from "@/util/dev.mjs";

export interface SurveyState {
  isSurveyDisabled: boolean;
  nextSurveySchedules: GameHash<number>;
  gamesUntilSurveyStart: null | number;
}

declare module "@/__main__/app-state.mjs" {
  interface FeatureState {
    survey: SurveyState;
  }
}

export async function setupState() {
  const survey = {
    isSurveyDisabled: false,
    nextSurveySchedules: makeHashByGames<number>(getFirstSurveySchedule()),
    gamesUntilSurveyStart: null,
  };
  addInitialPath(["survey"], survey);
  await readData(["survey"]);
}

export function teardownState() {
  delete writeState.survey;
}

export function updateSurvey(key, value) {
  const survey = clone(readState.survey);
  survey[key] = value;
  delete survey[isVolatile];
  survey[isPersistent] = MAX_TIME;
  writeState.survey = survey;
}

export const overrideTimesUntilFirstSurvey = (timesTillSurvey = 0) => {
  if (typeof timesTillSurvey !== "number") {
    devWarn("overrideTimesUntilFirstSurvey", "timesTillSurvey is not a number");
    return;
  }

  const survey = clone(readState.survey);
  const oldNumber = survey.gamesUntilSurveyStart;

  updateSurvey("gamesUntilSurveyStart", timesTillSurvey);

  devDebug(
    `gamesUntilSurveyStart was ${oldNumber} now is ${survey.gamesUntilSurveyStart}`,
  );
};

export const reduceTimesUntilFirstSurveyByOne = () => {
  const survey = clone(readState.survey);
  const oldNumber = survey.gamesUntilSurveyStart;
  survey.gamesUntilSurveyStart--;

  updateSurvey("gamesUntilSurveyStart", survey.gamesUntilSurveyStart);

  devDebug(
    `gamesUntilSurveyStart was ${oldNumber} now is ${survey.gamesUntilSurveyStart}`,
  );
};

export function resetSurvey() {
  updateSurvey("nextSurveySchedules", makeHashByGames<number>(0));
  updateSurvey("gamesUntilSurveyStart", 0);
}

export function getFirstSurveySchedule() {
  return new Date().getTime() + 1 * DAY; // 1 day later
}

const updateNextSurveySchedule = (gameSymbol: symbol, schedule: number) => {
  const shortName = GAME_SHORT_NAMES[gameSymbol];
  // force all other games to 6 WEEKS from now
  const nextSurveySchedules = makeHashByGames<number>(6 * WEEK);
  // update the current survery with 6 or 12 weeks
  nextSurveySchedules[shortName] = schedule;
  updateSurvey("nextSurveySchedules", nextSurveySchedules);
};

export function setNextSurveyScheduleAfterSubmit(gameSymbol: symbol) {
  const nextDate = new Date().getTime() + 12 * WEEK;
  updateNextSurveySchedule(gameSymbol, nextDate);
}

export function setNextSurveyScheduleAfterDismiss(gameSymbol: symbol) {
  const nextDate = new Date().getTime() + 6 * WEEK;
  updateNextSurveySchedule(gameSymbol, nextDate);
}

export const devHelpers = {
  resetSurvey,
};
