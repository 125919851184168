import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.jsx";

import { readState } from "@/__main__/app-state.mjs";
import type { Survey } from "@/feature-survey/constants.mjs";
import {
  CloseButton,
  ExternalSurveyContainer,
  ExternalSurveyContent,
  SurveyText,
} from "@/feature-survey/Survey.style.jsx";
import BlitzCloseIcon from "@/inline-assets/close-icon.svg";
import Message from "@/inline-assets/message.svg";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function ExternalSurvey({
  surveyIsOpen,
  surveyData,
}: {
  surveyIsOpen: React.MutableRefObject<boolean>;
  surveyData: Survey;
}) {
  const { t } = useTranslation();
  const { settings } = useSnapshot(readState);

  const { key, primaryMessage, secondaryMessage, externalLink, endDate } =
    surveyData;

  const [isDismissed, setIsDismissed] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isSurveyDisabled } = (settings.survey || {}) as any;

  const dismissKey = `DISMISSED_SURVEY_${key}`;
  const visitsKey = `VISITS_SURVEY_${key}`;

  const surveyVisits = JSON.parse(localStorage.getItem(visitsKey) || "[]");

  useEffect(() => {
    if (
      !Array.isArray(surveyVisits) ||
      !surveyData?.rules?.minVisitsBeforeShown ||
      surveyData?.rules?.minVisitsBeforeShown < surveyVisits.length
    )
      return;
    const now = Date.now();
    if (
      surveyVisits.length === 0 ||
      // last visit has to be more than 30 minutes ago
      now - surveyVisits[surveyVisits.length - 1] >= 30 * 60 * 1000
    ) {
      localStorage.setItem(visitsKey, JSON.stringify([...surveyVisits, now]));
    }
  }, [surveyVisits, visitsKey, surveyData?.rules?.minVisitsBeforeShown]);

  const handlePreSubmitCloseButton = useCallback(() => {
    const dismissedCount =
      Number.parseInt(localStorage.getItem(dismissKey)) || 0;
    const newDimissedCount = dismissedCount + 1;
    localStorage.setItem(dismissKey, newDimissedCount.toString());
    if (newDimissedCount > surveyData?.rules?.minDissmissalsBeforeHidden) {
      localStorage.setItem(`DISMISSED_SURVEY_${key}`, "true");
    }
    setIsDismissed(true);
    surveyIsOpen.current = false;
  }, [
    dismissKey,
    key,
    surveyData?.rules?.minDissmissalsBeforeHidden,
    surveyIsOpen,
  ]);

  const handleTakeSurveyButton = useCallback(() => {
    localStorage.setItem(`DISMISSED_SURVEY_${key}`, "true");
    setIsDismissed(true);
    surveyIsOpen.current = false;
  }, [key, surveyIsOpen]);

  if (
    (surveyData?.rules?.minVisitsBeforeShown &&
      surveyVisits.length < surveyData?.rules?.minVisitsBeforeShown) ||
    isDismissed ||
    isSurveyDisabled ||
    new Date() > endDate
  ) {
    return null;
  }

  return (
    <ExternalSurveyContainer>
      <ExternalSurveyContent>
        <SurveyText className="type-body2-form">
          <Message />
          <div>
            <div className="type-form--button">{t(...primaryMessage)}</div>
            {secondaryMessage && (
              <div className="type-caption shade1">
                {t(...secondaryMessage)}
              </div>
            )}
          </div>
        </SurveyText>
        <Button
          emphasis="high"
          href={externalLink}
          target="_blank"
          className="desktop-only"
          onClick={handleTakeSurveyButton}
        >
          {t("common:takeSurvey", "Take Survey")}
        </Button>
      </ExternalSurveyContent>
      <CloseButton
        type="submit"
        role="button"
        onClick={handlePreSubmitCloseButton}
      >
        <BlitzCloseIcon />
      </CloseButton>
    </ExternalSurveyContainer>
  );
}

export default ExternalSurvey;
