import mainRefs from "@/__main__/refs.mjs";
import {
  devHelpers,
  setupState,
  teardownState,
} from "@/feature-survey/actions.mjs";
import SurveyWrapper from "@/feature-survey/SurveyWrapper.jsx";
import { reRender } from "@/shared/EventedRender.jsx";
import globals from "@/util/global-whitelist.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const originals = mapOriginalRefs({
  mainRefComponents: mainRefs.components,
});

export function setup() {
  setupState();

  globals.__BLITZ_DEV__.survey = devHelpers;

  originals.append({
    mainRefComponents: {
      floatingElementsTop: [SurveyWrapper],
    },
  });

  reRender();
}

export function teardown() {
  teardownState();

  delete globals.__BLITZ_DEV__.survey;

  originals.restore();

  reRender();
}
