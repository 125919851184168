import { styled } from "goober";

import BlitzCheckIcon from "@/inline-assets/circle-check.svg";

export const SurveyMessageContainer = styled("div")<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? "grid" : "none")};
  align-items: center;
  width: 100%;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  grid-gap: var(--sp-2);
  height: 100%;
  opacity: 1;
  align-items: end;

  transition: opacity ease 0.3s 0.2s;

  .survey-text-field {
    margin: var(--sp-3) 0 0 0 !important;

    --border-color: transparent;
    border-radius: 8px;
    border: 3px solid var(--border-color);
    width: calc(var(--sp-3) * 26);

    &:focus-within {
      --border-color: var(
        --opacity-shades-shade-115,
        rgba(153, 156, 162, 0.15)
      );
    }

    & div[contenteditable] {
      height: unset;

      padding: var(--sp-1_5) var(--sp-2) !important;
    }
  }

  button {
    margin-bottom: calc(var(--sp-1_5) / 2);
  }
`;

export const SurveyContainer = styled("form")`
  --radius: var(--br);
  --factor: 2;

  display: flex;
  gap: var(--sp-3);
  row-gap: 0;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  bottom: var(--sp-6);
  left: 50%;
  min-height: var(--sp-12);
  transform: translateX(-50%);
  width: calc(var(--sp-1) * 105);
  border-radius: var(--radius);
  align-items: center;
  background-color: var(--shade10);
  padding: var(--sp-2) var(--sp-3);
  z-index: 14;
  box-shadow:
    0px 4px 12px rgba(7, 8, 12, 0.25),
    0px 12px 24px rgba(7, 8, 12, 0.25);

  &.result {
    max-width: unset;
    width: unset;

    transition: opacity 0.5s ease 5s;
    opacity: 0;
  }
`;

export const SurveyText = styled("p")<{ $sent?: boolean }>`
  display: flex;
  align-items: center;
  gap: var(--sp-3);

  letter-spacing: 0.01em !important;

  svg {
    width: var(--sp-4);
    height: var(--sp-4);
  }

  &.result {
    color: var(${({ $sent }) => ($sent ? "--turq" : "--shade2")});
    pointer-events: none;
  }
`;

export const SurveyRatingsContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-3);
`;

export const CloseButton = styled("button")`
  height: var(--sp-8_5);
  width: var(--sp-8_5);
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: var(--sp-2);
  right: var(--sp-2);
  transform: translateY(-1px);

  svg {
    height: 100%;
    width: 100%;
    color: var(--shade2);
  }

  transition: color 0.2s ease-in-out;

  &:hover {
    svg {
      color: var(--shade0);
    }
  }
`;

export const CheckIcon = styled(BlitzCheckIcon)`
  width: var(--sp-5);
  color: var(--shade0);
`;

export const StarButton = styled("span")`
  --color: var(--shade6);
  cursor: pointer;

  .survey-star {
    height: var(--sp-7);
    width: var(--sp-7);

    color: var(--color);

    &[data-selected="true"] {
      --color: var(--yellow);
    }
  }
`;

export const StarGroup = styled("div")`
  display: flex;
  flex-wrap: no-wrap;
`;

export const ExternalSurveyContainer = styled(SurveyContainer)`
  width: calc(var(--sp-1) * 120);
`;

export const ExternalSurveyContent = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-6);
  justify-content: space-between;
  width: 100%;
  padding-right: var(--sp-10);

  svg {
    width: var(--sp-7) !important;
    height: var(--sp-7) !important;
  }
`;
