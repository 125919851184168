import React, { useRef } from "react";

import { AVAILABLE_SURVEYS } from "@/feature-survey/constants.mjs";
import SurveyLink from "@/feature-survey/ExternalSurvey.jsx";
import Survey from "@/feature-survey/Survey.jsx";
import { devWarn } from "@/util/dev.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

function SurveyWrapper() {
  const route = useRoute({ isGlobal: true });
  const surveyIsOpen = useRef(false);

  const currentPath = route.currentPath ?? "";
  const searchParams = route.searchParams;

  const activeSurveys = AVAILABLE_SURVEYS.filter((survey) => {
    const routeMatch =
      survey?.routePath && currentPath.match(survey?.routePath);
    const searchMatch =
      survey?.searchParam && searchParams.has(survey?.searchParam);
    const routeSearchMatch =
      survey?.routePath && survey?.searchParam
        ? routeMatch && searchMatch
        : routeMatch || searchMatch;
    return (
      routeSearchMatch &&
      (survey.endDate ? new Date() < survey.endDate : true) &&
      localStorage.getItem(`DISMISSED_SURVEY_${survey.key}`) !== "true"
    );
  });

  if (activeSurveys.length > 1) {
    devWarn("Multiple active surveys found for the same route");
  }

  const [activeSurvey] = activeSurveys;

  if (activeSurvey) {
    surveyIsOpen.current = true;
  }

  if (activeSurvey?.externalLink) {
    return <SurveyLink surveyIsOpen={surveyIsOpen} surveyData={activeSurvey} />;
  }

  if (surveyIsOpen.current) {
    return <Survey surveyIsOpen={surveyIsOpen} surveyData={activeSurvey} />;
  }

  return null;
}

export default SurveyWrapper;
